<script setup lang="ts">
import type { ConcreteComponent } from "vue"

const { notifications } = useNotifications()
function removeNotification(id: string) {
  notifications.value = notifications.value.filter((n) => n.id !== id)
}

const notificationTypeColorMap: Record<NotificationType, string> = {
  info: "text-blue-400",
  warning: "text-yellow-400",
  error: "text-red-400",
  success: "text-green-400",
}
const notificationIconMap: Record<
  NotificationType,
  string | ConcreteComponent
> = {
  info: resolveComponent("SvgoPikaInformationCircle"),
  warning: resolveComponent("SvgoPikaWarning"),
  error: resolveComponent("SvgoPikaClose"),
  success: resolveComponent("SvgoPikaCheck"),
}
</script>

<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <Teleport to="body">
    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 z-100 flex items-end px-4 py-6 sm:p-6"
    >
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <TransitionGroup
        name="notification"
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-32 opacity-0 blur-xl scale-50"
        enter-to-class="translate-y-0 opacity-100 scale-100 blur-none sm:translate-x-0"
        move-class="transition ease-in duration-100"
        leave-active-class="transition absolute ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        class="flex w-full flex-col items-center space-y-4 sm:items-end"
        tag="div"
      >
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white/90 shadow-xs ring-1 ring-slate-300 backdrop-blur-sm"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="shrink-0">
                <component
                  :is="notificationIconMap[notification.type]"
                  class="size-6"
                  :class="notificationTypeColorMap[notification.type]"
                />
              </div>
              <div class="ml-3 w-0 flex-1 space-y-1 pt-0.5">
                <p
                  v-if="notification.title"
                  class="text-sm font-medium text-gray-900"
                >
                  {{ notification.title }}
                </p>
                <p
                  v-if="typeof notification.description == 'string'"
                  class="text-sm text-gray-500"
                >
                  {{ notification.description }}
                </p>
                <ul v-else-if="notification.description" class="list-disc">
                  <li
                    v-for="(descriptionLine, index) in notification.description"
                    :key="index"
                    class="mt-1 ml-4 text-sm text-gray-500"
                  >
                    {{ descriptionLine }}
                  </li>
                </ul>
              </div>
              <ProgressCloseButton
                :duration="notification.duration * 1000"
                @click="removeNotification(notification.id)"
              />
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </Teleport>
</template>
