<script setup lang="ts">
const props = defineProps<{
  radius: number
  progress?: number
  stroke: number
  /** Duration in ms */
  duration?: number
  /** Whether or not the ring should fade out after `duration` */
  disappearAfter?: boolean
  dark?: boolean
}>()

const normalizedRadius = computed(() => props.radius - props.stroke * 2)
const circumference = computed(() => 2 * Math.PI * normalizedRadius.value)
const strokeDashoffset = computed(() =>
  props.progress
    ? circumference.value - (props.progress / 100) * circumference.value
    : 0,
)
const animationDuration = computed(() =>
  props.duration ? `${props.duration}ms` : "0ms",
)

/** If progress is undefined show progressPath for animation.
 *If progress is defined but 0, hide the path. Otherwise it will show 100% progress
 */
const showProgressPath = computed(() => {
  return props.progress == undefined || props.progress != 0
})

defineEmits(["progress-finished"])
</script>

<template>
  <svg
    :class="{ 'fade-out': !!duration && disappearAfter }"
    :height="radius * 2"
    :width="radius * 2"
    @animationend="$emit('progress-finished')"
  >
    <circle
      :stroke="dark ? 'rgb(30 41 59)' : '#e2e8f0'"
      :stroke-width="stroke"
      fill="transparent"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
    <circle
      v-if="showProgressPath"
      class="path origin-[50%_50%] -rotate-90"
      stroke="currentColor"
      :stroke-dasharray="`${circumference} ${circumference}`"
      :stroke-width="stroke"
      fill="transparent"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>
<style scoped>
.path {
  animation-name: dash;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: v-bind("animationDuration");
  stroke-dashoffset: v-bind("circumference");
}
.fade-out {
  animation-name: fadeout;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: 250ms;
  animation-delay: v-bind("animationDuration");
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: v-bind("strokeDashoffset");
  }
}
</style>
