<script setup lang="ts">
import type { Locale } from "@intlify/core-base"
import type { ItemType } from "~ui/components/form/DropdownMenu.vue"
import type { PopoverContentProps } from "reka-ui"

const { locales = ["da_DK", "en_US", "de_DE", "nb_NO", "sv_SE", "pt_PT"] } =
  defineProps<{
    locales?: Locale[]
    popoverSide?: PopoverContentProps["side"]
  }>()

const { t, locale, setLocale } = useI18n()
const items: ItemType[] = [
  {
    id: "da_DK",
    label: t("locale.danish"),
    icon: resolveComponent("SvgoDanish"),
  },
  {
    id: "en_US",
    label: t("locale.english"),
    icon: resolveComponent("SvgoEnglish"),
  },
  {
    id: "de_DE",
    label: t("locale.german"),
    icon: resolveComponent("SvgoGerman"),
  },
  {
    id: "nb_NO",
    label: t("locale.norwegian"),
    icon: resolveComponent("SvgoNorwegian"),
  },
  {
    id: "sv_SE",
    label: t("locale.swedish"),
    icon: resolveComponent("SvgoSwedish"),
  },
  {
    id: "pt_PT",
    label: t("locale.portugese"),
    icon: resolveComponent("SvgoPortugese"),
  },
] as const

const filteredItems = computed(() => {
  return items.filter((item) => locales?.includes(item.id as Locale))
})

const emit = defineEmits<{
  (e: "openLocale"): void
  (e: "selectLocale", id: string): void
}>()

function handleChoice(choice: ItemType) {
  setLocale(choice.id as Locale)
  emit("selectLocale", choice.id)
}
const iconComponent = computed(() => {
  const item = items.find((item) => locale.value == item.id)
  return item?.icon
})
</script>

<template>
  <FormDropdownMenu
    :class="$attrs.class"
    :side="popoverSide"
    :items="filteredItems"
    @open="$emit('openLocale')"
    @choose="handleChoice"
  >
    <slot>
      <FormButton
        data-testid="locale_switcher"
        variant="secondary"
        :class="$attrs.class"
      >
        <span class="block pr-4 text-sm font-medium"
          >{{ $t("locale.current") }}
        </span>
        <component
          :is="iconComponent"
          v-if="iconComponent"
          class="pointer-events-none size-5"
        />
        <SvgoEnglish v-else class="pointer-events-none size-5" />
      </FormButton>
    </slot>
  </FormDropdownMenu>
</template>
